import React from 'react'
import { Router } from '@reach/router'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import { CourseManager } from '../components/course'
import coursePath from '../components/shared/course-path'
import InstructorDashboard from '../components/dashboard/instructor'

const CourseDescription = ({ course, path }) => (
  <>
    <h2 style={{ display: 'inline-block' }}>{course.name}</h2>
    <CourseManager course={course} />
  </>
)

const Dashboard = ({ courseId, path }) => (
  <InstructorDashboard courseId={courseId} />
)

const CoursePage = ({ data }) => {
  const course = data.strapiCourse
  const { strapi_id: courseId } = course
  const path = coursePath(course)
  return (
    <Layout tocEnabled={false} objectInfo={course}>
      <Router basepath={path}>
        <Dashboard path="/dashboard" courseId={courseId} />
        <CourseDescription path="/" course={course} />
      </Router>
    </Layout>
  )
}

/*student {
          firstName
          lastName
          usosID
        }*/

export const coursePageQuery = graphql`
  query CourseQuery($id: Int) {
    strapiCourse(strapi_id: { eq: $id }) {
      __typename
      strapi_id
      updatedAt
      name
      semester {
        season
        year
      }
      slug
      usos
      description {
        data {
          childMarkdownRemark {
            htmlAst
          }
        }
      }
      finalProject
      projects {
        strapi_id
        title
        accepted
        presentationDateTime
        description {
          data {
            childMarkdownRemark {
              htmlAst
            }
          }
        }
      }
      lectures {
        strapi_id
        name
        date
        files {
          caption
          localFile {
            name
            extension
            url
          }
        }
        recording
        comments {
          data {
            childMarkdownRemark {
              htmlAst
            }
          }
        }
        tutorial {
          strapi_id
          sheet
          priorityDeadline
          date
          files {
            caption
            localFile {
              name
              extension
              url
            }
          }
          problems {
            strapi_id
            name
            number
            mandatory
            assignAll
            points
          }
        }
      }
    }
  }
`

export default CoursePage
